<template>
  <div>
    <header>
      <m-page-header type="link" :title="tegral_title" />
    </header>
    <div class="main">
      <div class="select_good">
        <div class="goods_reep" v-show="!loading">
          <p>商品</p>
          <div class="good_list" v-for="item in goodsList" :key="item.id">
            <div class="img_url">
              <a target="_blank" :href="item.goods_detail_url">
                <el-image
                  style="width: 80px; height: 80px; border-radius: 4px"
                  :src="item.image"
                  fit="cover"
                >
                  <template #error>
                    <div class="image-slot">
                      <img src="../../../assets/img/goodserro.png" alt="" />
                    </div>
                  </template>
                </el-image>
              </a>
            </div>
            <div>
              <p>
                {{ item.base_name }}
                <el-tag type="danger" v-show="item.status === '2'"> 已失效 </el-tag>
              </p>
              <p>{{ item.codeno }}</p>
              <p style="font-weight: bold">
                <!-- <span>{{ item.currency.symbol_left + ' ' }}</span> -->
                <span>{{ item.price }}</span>
                <span>{{ ' ' + item.currency.symbol_right }}</span>
              </p>
            </div>
          </div>
        </div>
        <div>
          <el-button type="primary" size="small" @click="selectGoods">选择商品</el-button>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="tabmain">
      <p>规格列表</p>
      <div class="list-wrap">
        <m-table :data="dataList" v-loading="loading">
          <template #empty>
            <el-empty description="暂无数据"></el-empty>
          </template>
          <el-table-column label="规格" prop="sku_value"> </el-table-column>
          <el-table-column width="300px" label="库存" prop="stock_nums"> </el-table-column>
          <el-table-column width="200px" label="积分数量">
            <template #default="tableItem">
              <el-input
                v-model="tableItem.row.points"
                size="small"
                maxlength="11"
                clearable
                @input="handleInput(tableItem.$index)"
              ></el-input>
            </template>
          </el-table-column>
        </m-table>
      </div>
      <footer>
        <div class="btnok">
          <el-button type="primary" @click="setGoods" :loading="loading">保存</el-button>
        </div>
      </footer>
    </div>
    <SelectGoods
      v-if="model.hackGoods"
      :visible="model.hackGoods"
      @close="close"
      @selectOk="selectOk"
      chooseType="radio"
    />
  </div>
</template>

<script>
import MPageHeader from '@/components/pageHeader/PageHeader.vue';
import SelectGoods from '@/components/selectGoods/Index.vue';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { _throttle } from '@/tools/index';
import { getGoodsPoint, SetGoodsPoint, getSku } from '../api/buypoints';
import { ElMessage } from 'element-plus';
export default {
  name: 'NewIntegral',
  components: {
    'm-page-header': MPageHeader,
    SelectGoods,
  },
  setup() {
    // 初始化 列表数据
    const dataList = ref([]);
    const goodsList = ref([]);
    const router = useRoute();
    const tegral_title = ref('新建积分');
    router.params.id ? (tegral_title.value = '编辑积分') : '新建积分';
    const model = ref({
      hackGoods: false,
    });
    const goods_data = ref({
      goods_id: router.params.id,
      status: '',
      id: '',
      goods_list: {
        currency: {},
      },
    });
    const loading = ref(false);
    async function getGoods() {
      const parms = {
        goods_id: goods_data.value.goods_id,
      };
      const { data } = await getGoodsPoint(parms, loading);
      data.sku_list.map(res => {
        res.sku_code === '0_0' ? (res.sku_value = '单规格') : '';
      });
      data.price = data.currency.symbol_left + data.price;
      data.status = router.params.status;
      dataList.value = data.sku_list;
      goods_data.value.id = data.primary_id;
      goodsList.value.push(data);
    }
    const sku_point = ref({}); //规格数据
    function handleInput(index) {
      dataList.value[index].points = dataList.value[index].points.replace(/[^\d]/g, '');
    }
    function setGoods() {
      if (router.params.status == '2') {
        return ElMessage.warning({
          message: '商品已失效,请重新选择商品!',
          type: 'warning',
        });
      }
      if (!goods_data.value.goods_id) {
        return ElMessage.warning({
          message: '请选择商品!',
          type: 'warning',
        });
      }
      dataList.value.map(res => {
        sku_point.value[res.sku_code] = res.points;
      });
      for (let i = 0; i < dataList.value.length; i++) {
        if (!dataList.value[i].points && dataList.value[i].points !== 0) {
          ElMessage.warning({
            message: '请填写每一项积分数量!',
            type: 'warning',
          });
          return false;
        }
      }
      const parms = {
        goods_id: goods_data.value.goods_id,
        sku_point: sku_point.value,
        id: router.params.status ? goods_data.value.id : '0',
      };
      loading.value = true;
      SetGoodsPoint(parms)
        .then(() => {
          ElMessage.success({
            message: '操作成功!',
            type: 'success',
          });
          loading.value = false;
          history.go(-1);
        })
        .catch(() => {
          loading.value = false;
        });
    }
    function selectGoods() {
      model.value.hackGoods = true;
    }
    function close() {
      model.value.hackGoods = false;
    }
    //选中数据回调
    function selectOk(data) {
      if (data.length > 0 || data.value) {
        goodsList.value = [];
        goods_data.value.goods_id = data.value.id;
        data.value.status = '1';
        data.value.currency = {
          symbol_left: '',
          symbol_right: '',
        };
        goodsList.value.push(data.value);
        sku(data.value.id);
      }
    }
    //获取商品规格
    async function sku(id) {
      dataList.value = [];
      loading.value = true;
      const parms = {
        id: id,
      };
      const { data } = await getSku(parms);
      loading.value = false;
      data.list.map(res => {
        res.sku_code === '0_0' ? (res.sku_value = '单规格') : '';
        dataList.value.push({
          id: res.id,
          sku_code: res.sku_code,
          sku_value: res.sku_value,
          stock_nums: res.stock_nums,
          points: '',
        });
      });
    }
    return {
      dataList,
      loading,
      goods_data,
      sku_point,
      tegral_title,
      model,
      goodsList,
      getGoods,
      handleInput,
      close,
      setGoods,
      selectOk,
      selectGoods,
    };
  },
  mounted() {
    this.goods_data.goods_id ? this.getGoods() : '';
  },
  methods: {
    save: _throttle(function () {
      this.setGoods();
    }, 2000),
  },
};
</script>

<style scoped lang="scss">
.main {
  //padding: 15px 20px;
  .list-wrap {
    margin-top: 15px;
  }
  .select_good {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    .goods_reep {
      flex: 0 0 80%;
    }
    .good_list {
      display: flex;
      margin-top: 24px;
      > div {
        flex: 1;
      }
      .img_url {
        flex: 0 0 95px;
      }
      p {
        padding-bottom: 15px;
        vertical-align: top;
      }
    }
  }
}
.tabmain {
  padding: 20px 20px 0 20px;
  position: relative;
  > p {
    padding: 10px 0;
  }
  footer {
    width: 100%;
    left: 0;
    bottom: 0;
    .btnok {
      height: 60px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      button {
        min-width: 140px;
      }
    }
  }
}
.line {
  width: 100%;
  height: 10px;
  background-color: #f9f9f9;
}
</style>
